<template>
  <div class="app-sidebar sidebar-slide-left">
    <div class="text-right">
      <button type="button" class="btn btn-sidebar btn-sidebar-close">
        <span class="x"></span>
      </button>
    </div>
    <div
      v-if="!showAvatar && !this.layout.logo && readyToRender"
      class="sidebar-header"
    >
      <router-link to="/">
        <svg
          id="Logo"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 520 111"
          enable-background="new 0 0 249.5 45"
        >
          <g fill="#ff7800" clip-rule="evenodd" fill-rule="evenodd">
            <path
              class="cls-1"
              d="M493.69,7.17,482.32,18.54l-4.85,4.85-7.9-7.9-6.17,6.18,11,11A4.38,4.38,0,0,0,477.51,34h.07a4.37,4.37,0,0,0,3.12-1.3l1.62-1.61,17.62-17.63Z"
            />
            <path
              class="cls-1"
              d="M66,31.74A26.78,26.78,0,0,0,52.5,28.25q-11,0-16.74,7.09V29.06H21v74.11H36.46V77.76q5.84,6.57,16,6.58A26.69,26.69,0,0,0,66,80.84,24.77,24.77,0,0,0,75.57,71,30.2,30.2,0,0,0,79,56.3a30.17,30.17,0,0,0-3.47-14.73A24.89,24.89,0,0,0,66,31.74ZM59.58,67.28a12.74,12.74,0,0,1-9.76,4.1,12.73,12.73,0,0,1-9.75-4.1q-3.81-4.1-3.81-11t3.81-11a12.73,12.73,0,0,1,9.75-4.1,12.74,12.74,0,0,1,9.76,4.1q3.81,4.11,3.81,11T59.58,67.28Z"
            />
            <path
              class="cls-1"
              d="M128,34.83q-5.74-6.57-15.94-6.58A27.24,27.24,0,0,0,98.4,31.69a24.46,24.46,0,0,0-9.56,9.83A30.47,30.47,0,0,0,85.38,56.3a30.47,30.47,0,0,0,3.46,14.78A24.38,24.38,0,0,0,98.4,80.9,27.24,27.24,0,0,0,112,84.34q10.89,0,16.63-7.09v6.28h14.76V29.06H128ZM124.4,67.28a13.65,13.65,0,0,1-19.51,0q-3.87-4.1-3.87-11t3.87-11a13.65,13.65,0,0,1,19.51,0q3.85,4.11,3.86,11T124.4,67.28Z"
            />
            <path
              class="cls-1"
              d="M194,36.05q-6-7.8-17.63-7.8A27.69,27.69,0,0,0,163,31.55a24.82,24.82,0,0,0-9.66,9.25,26.37,26.37,0,0,0-3.56,13.78,26.29,26.29,0,0,0,3.56,13.76A24.8,24.8,0,0,0,163,77.61a27.79,27.79,0,0,0,13.41,3.29q10.8,0,16.84-6.79v2.33q0,7.5-3.81,11.19t-11.84,3.69a33.08,33.08,0,0,1-10.3-1.67,25,25,0,0,1-8.51-4.5l-6.14,11.34a31.79,31.79,0,0,0,11.48,5.57A54.69,54.69,0,0,0,178.76,104q14.66,0,22.28-7.29t7.63-22.27V29.06H194Zm-4.5,28.2a14.06,14.06,0,0,1-10,3.69,14.24,14.24,0,0,1-10.11-3.69,12.63,12.63,0,0,1-4-9.67,12.6,12.6,0,0,1,4-9.67,14.25,14.25,0,0,1,10.11-3.7,14.07,14.07,0,0,1,10,3.7,12.7,12.7,0,0,1,3.91,9.67A12.73,12.73,0,0,1,189.51,64.25Z"
            />
            <path
              class="cls-1"
              d="M244.62,41.21q7.81,0,12,7.49l12-6.58a21.12,21.12,0,0,0-9.16-10.27,29.1,29.1,0,0,0-14.71-3.6,32.14,32.14,0,0,0-15.3,3.6,26.51,26.51,0,0,0-10.59,10A27.59,27.59,0,0,0,215,56.3a27.64,27.64,0,0,0,3.81,14.48,26.58,26.58,0,0,0,10.59,10,32.25,32.25,0,0,0,15.3,3.59,28.91,28.91,0,0,0,14.71-3.64,21.61,21.61,0,0,0,9.16-10.13l-12-6.68q-4.07,7.49-12,7.49a13.41,13.41,0,0,1-10-4q-4-4.05-4-11t4-11A13.41,13.41,0,0,1,244.62,41.21Z"
            />
            <path
              class="cls-1"
              d="M327.25,41.06a24.92,24.92,0,0,0-9.95-9.51,29.18,29.18,0,0,0-13.86-3.3,29.57,29.57,0,0,0-14.61,3.6,26.33,26.33,0,0,0-10.2,10,28.37,28.37,0,0,0-3.71,14.43,28,28,0,0,0,3.76,14.48,26,26,0,0,0,10.7,10,34.19,34.19,0,0,0,15.94,3.59q14.46,0,22-8.81l-8.22-9.11a18,18,0,0,1-6,4,20.08,20.08,0,0,1-7.37,1.26,17.93,17.93,0,0,1-8.87-2,12,12,0,0,1-5.3-6.08L331,55.79A28.18,28.18,0,0,0,327.25,41.06ZM290.07,54.68q.19-6.78,3.86-10.63a12.52,12.52,0,0,1,9.51-3.85,13.12,13.12,0,0,1,8.12,2.53A11.92,11.92,0,0,1,316,49.51Z"
            />
            <path
              class="cls-1"
              d="M359.54,30.28a17.25,17.25,0,0,0-7.48,6V29.06H337.31V83.53h15.45V57.81q0-7.59,3.76-11.39t10.4-3.79c.59,0,1.74.07,3.46.2V29C366.29,29,362.67,28.93,359.54,30.28Z"
            />
            <path
              class="cls-1"
              d="M398.7,71.78a6.18,6.18,0,0,1-4.85-1.87,7.69,7.69,0,0,1-1.68-5.31V42.43h13.17V29H392.17V17H376.72V64.8q0,9.61,5.15,14.58t14.76,5a27.48,27.48,0,0,0,6.88-.86,15.61,15.61,0,0,0,5.59-2.58L405,69.76A10.47,10.47,0,0,1,398.7,71.78Z"
            />
            <path
              class="cls-1"
              d="M456,31.85a33.36,33.36,0,0,0-30.15,0,26.34,26.34,0,0,0-10.45,10,27.94,27.94,0,0,0-3.76,14.48,28,28,0,0,0,3.76,14.48,26.4,26.4,0,0,0,10.45,10,33.45,33.45,0,0,0,30.15,0,26.47,26.47,0,0,0,10.4-10,28,28,0,0,0,3.77-14.48,27.94,27.94,0,0,0-3.77-14.48A26.4,26.4,0,0,0,456,31.85Zm-5.29,35.43a13.71,13.71,0,0,1-19.56,0q-3.87-4.1-3.87-11t3.87-11a13.71,13.71,0,0,1,19.56,0q3.81,4.11,3.81,11T450.71,67.28Z"
            />
          </g>
        </svg>
      </router-link>
    </div>
    <div class="username mb-4">
      <router-link v-if="showAvatar" to="/">
        <WhiteLabel>
          <Avatar :name="userName" class="mt-5" />
        </WhiteLabel>
      </router-link>
      <router-link v-if="this.layout.logo" to="/">
        <WhiteLabel>
          <img class="white-label__logo" :src="this.layout.logo" alt="Logo" />
        </WhiteLabel>
      </router-link>
      <span>{{ userName | abbreviateName(18) }}</span>
      <br />
      <small class="m-0 text-muted">{{ email }}</small>
      <br />
      <p v-if="isAccountHolder" class="m-0 text-muted">TITULAR DA CONTA</p>
      <p v-else v-text="roleName" class="m-0 text-muted"></p>
    </div>
    <div
      id="sidebar-nav"
      class="sidebar-nav mb-4"
      data-children=".sidebar-nav-group"
      v-if="accountExpired"
    >
      <div class="mx-4 mb-4">
        <WhiteLabel>
          <Selector
            v-if="
              (whoAmI.accessedByPartner || whoAmI.accessedByEmployee) &&
              showNavigationSwitcher
            "
            :defaultIndex="getNavigation() === 'partner' ? 1 : 0"
            :defaultOptions="navigationOptions"
            :callback="changeNavigation"
            class="m-auto"
          />
        </WhiteLabel>
      </div>
      <div class="sidebar-nav-group" v-if="!this.isNavigation('partner')">
        <router-link
          :to="{ name: 'root' }"
          class="sidebar-nav-link no-drop "
          v-if="dashboardPermission"
        >
          <i class="icon-home"></i> Início
        </router-link>

        <router-link
          :to="{ name: 'charts' }"
          class="sidebar-nav-link no-drop "
          v-if="dashboardPermission && notHideTheButton('indicadores')"
        >
          <i class="icon-chart"></i> Indicadores
        </router-link>

        <a
          href="#financial"
          class="sidebar-nav-link "
          data-toggle="collapse"
          v-if="
            financialPermission &&
            notHideTheButton('minhas-transacoes') &&
            (notHideTheButton('minhas-transacoes-cartao-boleto') ||
              notHideTheButton('minhas-transacoes-links-cobranca') ||
              notHideTheButton('minhas-transacoes-antecipacao-recebiveis'))
          "
        >
          <i class="icon-calculator"></i> Minhas Transações
        </a>
        <div id="financial" class="collapse" data-parent="#sidebar-nav">
          <router-link
            :to="{
              name:
                this.getNavigation() === 'holder'
                  ? 'sellers-sales-history'
                  : 'sales-history',
            }"
            class="sidebar-nav-link"
            v-if="
              financialPermission &&
              notHideTheButton('minhas-transacoes-cartao-boleto')
            "
            >Cartão e boleto</router-link
          >
          <router-link
            :to="{ name: 'linkpayment' }"
            class="sidebar-nav-link"
            v-if="notHideTheButton('minhas-transacoes-links-cobranca')"
            >Links de cobrança</router-link
          >
          <!-- <router-link
            :to="{ name: 'anticipation' }"
            class="sidebar-nav-link"
            v-if="
              anticipationPermission &&
              notHideTheButton('minhas-transacoes-antecipacao-recebiveis')
            "
            >Antecipação de recebíveis</router-link
          > -->
        </div>

        <a
          href="#account"
          class="sidebar-nav-link "
          data-toggle="collapse"
          v-if="
            notHideTheButton('conta') &&
            (notHideTheButton('conta-saldo-extrato') ||
              notHideTheButton('conta-transferencias') ||
              notHideTheButton('conta-pagamento-contas') ||
              notHideTheButton('conta-proximos-lancamentos') ||
              notHideTheButton('conta-preferencias'))
          "
        >
          <i class="icon-wallet"></i> Conta
        </a>
        <div id="account" class="collapse" data-parent="#sidebar-nav">
          <router-link
            :to="{ name: 'balance-extract' }"
            class="sidebar-nav-link"
            v-if="
              dashboardPermission && notHideTheButton('conta-saldo-extrato')
            "
            >Saldo e Extrato</router-link
          >
          <router-link
            :to="{ name: 'transference' }"
            class="sidebar-nav-link"
            v-if="
              dashboardPermission && notHideTheButton('conta-transferencias')
            "
            >Transferências</router-link
          >
          <router-link
            :to="{ name: 'bills-payment' }"
            class="sidebar-nav-link"
            v-if="
              dashboardPermission && notHideTheButton('conta-pagamento-contas')
            "
            >Pagamento de contas
          </router-link>
          <router-link
            :to="{ name: 'upcoming-releases' }"
            class="sidebar-nav-link"
            v-if="
              upcomingReleasesPermission &&
              notHideTheButton('conta-proximos-lancamentos')
            "
            >Próximos lançamentos</router-link
          >
          <router-link
            :to="{ name: 'configure-limits' }"
            class="sidebar-nav-link"
            >Limites de transferências
          </router-link>
          <router-link
            :to="{ name: 'limites' }"
            class="sidebar-nav-link"
            >Limites de boletos
          </router-link>
          <!-- <a
            @click="openModal"
            class="sidebar-nav-link cursor-pointer"
            v-if="
              paymentAccountTransferManagementPermission &&
              notHideTheButton('conta-preferencias')
            "
            >Preferências</a
          > -->
          <router-link
            :to="{ name: 'proof-of-income' }"
            class="sidebar-nav-link"
            v-if="dirfPermission"
            >DIRF</router-link
          >
        </div>
        <!-- PIX SECTION -->
        <a
          href="#pix"
          class="sidebar-nav-link "
          data-toggle="collapse"
          v-if="
            financialPermission &&
            notHideTheButton('pix') &&
            (notHideTheButton('pix-minhas-chaves') ||
              notHideTheButton('pix-minhas-movimentacoes'))
          "
        >
          <PixLogo />
          Área Pix
        </a>
        <div id="pix" class="collapse" data-parent="#sidebar-nav">
          <router-link
            :to="{ name: 'pix' }"
            class="sidebar-nav-link"
            v-if="notHideTheButton('pix-minhas-chaves')"
          >
            Minhas chaves
          </router-link>
          <router-link
            :to="{ name: 'qrcodes' }"
            class="sidebar-nav-link"
            v-if="notHideTheButton('pix-meus-qrcodes')"
          >
            Meus QR Codes
          </router-link>
          <router-link
            :to="{ name: 'pix-order-payments' }"
            class="sidebar-nav-link"
            v-if="notHideTheButton('pix-minhas-movimentacoes')"
          >
            Pagar e transferir
          </router-link>
          <router-link
            :to="{ name: 'pix-claims' }"
            class="sidebar-nav-link"
            v-if="notHideTheButton('pix-minhas-chaves')"
          >
            Portabilidades e Reivindicações
          </router-link>
          <router-link
            :to="{ name: 'pix-limits' }"
            class="sidebar-nav-link"
            v-if="notHideTheButton('pix-limites')"
          >
            Limites
          </router-link>
        </div>

        <a
          href="#splitter"
          class="sidebar-nav-link "
          data-toggle="collapse"
          v-if="
            financialPermission &&
            notHideTheButton('splitter') &&
            (notHideTheButton('splitter-cadastros') ||
              notHideTheButton('splitter-transferencias'))
          "
        >
          <i class="icon-calculator"></i> Splitter
        </a>
        <div id="splitter" class="collapse" data-parent="#sidebar-nav">
          <router-link
            :to="{ name: 'splitter' }"
            class="sidebar-nav-link"
            v-if="financialPermission && notHideTheButton('splitter-cadastros')"
            >Cadastros</router-link
          >
          <router-link
            :to="{ name: 'splitter-transference' }"
            class="sidebar-nav-link"
            v-if="
              financialPermission && notHideTheButton('splitter-transferencias')
            "
            >Transferências</router-link
          >
        </div>

        <router-link
          :to="{ name: 'customer' }"
          class="sidebar-nav-link no-drop "
          v-if="customersHolderPermission && notHideTheButton('clientes')"
        >
          <i class="icon-people"></i> Clientes
        </router-link>
        <a
          href="#access-control"
          class="sidebar-nav-link "
          data-toggle="collapse"
          v-if="
            accessControlPermission &&
            notHideTheButton('controle-acesso') &&
            (notHideTheButton('controle-acesso-perfis-usuarios') ||
              notHideTheButton('controle-acesso-usuarios'))
          "
        >
          <i class="icon-equalizer"></i> Controle de acesso
        </a>
        <div id="access-control" class="collapse" data-parent="#sidebar-nav">
          <router-link
            :to="{ name: 'roles' }"
            class="sidebar-nav-link"
            v-if="
              rolesPermission &&
              notHideTheButton('controle-acesso-perfis-usuarios')
            "
            >Perfis de usuários</router-link
          >
          <router-link
            :to="{ name: 'user' }"
            class="sidebar-nav-link"
            v-if="
              usersPermission && notHideTheButton('controle-acesso-usuarios')
            "
            >Usuários</router-link
          >
           <router-link
            :to="{ name: 'device' }"
            class="sidebar-nav-link"
            v-if="isNaturalPerson &&
                  notHideTheButton('pix') &&
                  (notHideTheButton('pix-minhas-chaves') ||
                  notHideTheButton('pix-minhas-movimentacoes'))"
            >Dispositivos</router-link
          >
        </div>

      </div>
      <div class="sidebar-nav-group" v-if="this.isNavigation('partner')">
        <router-link
          :to="{ name: 'charts' }"
          class="sidebar-nav-link no-drop "
          v-if="dashboardPermission"
        >
          <i class="icon-chart"></i> Indicadores
        </router-link>

        <a
          href="#financial"
          class="sidebar-nav-link "
          data-toggle="collapse"
          v-if="financialPermission"
        >
          <i class="icon-calculator"></i> Transações
        </a>
        <div id="financial" class="collapse" data-parent="#sidebar-nav">
          <router-link
            :to="{ name: 'sellers-sales-history' }"
            class="sidebar-nav-link"
            v-if="financialPermission"
            >Cartão e boleto</router-link
          >
        </div>

        <a
          href="#sellers"
          class="sidebar-nav-link "
          data-toggle="collapse"
          v-if="financialPermission"
        >
          <i class="icon-calculator"></i> Estabelecimentos
        </a>
        <div id="sellers" class="collapse" data-parent="#sidebar-nav">
          <router-link
            :to="{ name: 'seller' }"
            class="sidebar-nav-link"
            v-if="financialPermission"
            >Cadastros</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import paggcerto from 'paggcerto-lightbox/src/js';
import { mapActions, mapGetters } from 'vuex';
import swal from 'sweetalert2';
import { PermissionList } from '@/models/Permission';
import LayoutApi from '@/services/v2/LayoutApi';
// import AppModalPreferencesBase from '@/components/account/preferences/ModalPreferencesBase';
import PaymentAccountsApi from '@/services/v1/PaymentAccountsApi';
import WhiteLabel from '@/components/shared/WhiteLabel';
import Selector from '@/components/shared/Selector';
import Avatar from '@/components/shared/Avatar';
import AccountApi from '../../services/AccountApi';
import PixLogo from '@/components/shared/PixLogo';

export default {
  name: 'AppSidebar',
  components: {
    // AppModalPreferencesBase,
    WhiteLabel,
    Avatar,
    Selector,
    PixLogo,
  },
  async beforeMount() {
    if (window.localStorage.getItem('applicationName')) {
      document.title = `${window.localStorage.getItem(
        'applicationName'
      )} - NetBanking`;
    }

    const accountApi = new AccountApi();
    accountApi.features.getList().then((x) => {
      this.listHiddenButtons = x.data.listFeatures;
    });

    const api = new LayoutApi();
    const subdomainRegex = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i;
    const subdomain = window.location.origin.match(subdomainRegex);

    await this.askWhoAmI();

    this.validSubdomain = subdomain[1];

    if (!subdomain || subdomain[1] === 'gestao') {
      this.showAvatar = false;
      this.showNavigationSwitcher = false;
      this.readyToRender = true;
      return;
    }

    this.subdomain = subdomain ? subdomain[1] : null;
    // this.validSubdomain = this.subdomain;

    const layout = subdomain
      ? await api.fetch(subdomain[1])
      : await api.fetch();

    if (layout.logo) {
      this.showAvatar = false;
      this.layout.logo = layout.logo;
      this.readyToRender = true;

      let link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement('link');
      link.type = 'image/png';
      link.rel = 'icon';
      link.href = layout.logo;
      document.getElementsByTagName('head')[0].appendChild(link);
    } else {
      this.showAvatar = true;
      this.readyToRender = true;
    }

    if (layout.applicationName) {
      this.showNavigationSwitcher = true;
    }
  },
  async mounted() {
    /* eslint-disable no-undef */
    $(() => {
      $('.btn-sidebar-open').on('click', () => this.show());
      $('.btn-sidebar-close').on('click', () => this.hide());
    });

    this.$bus.$emit('spinner-run');
    // if (process.env.NODE_ENV === 'production') {
    //   /* eslint-disable no-undef */
    //   $('#md-chat-widget').remove()

    //   window.name = this.whoAmI.user.fullName
    //   window.email = this.whoAmI.user.email
    //   window.phoneNumber = ''
    //   window.logged = true
    //   window.mdChatClient = `${process.env.VUE_APP_MOVIDESK_KEY}`

    //   let movideskScript = document.createElement('script')
    //   movideskScript.setAttribute('src', `${process.env.VUE_APP_MOVIDESK_URL}`)
    //   document.head.appendChild(movideskScript)
    // }

    if (this.whoAmI.user.role !== null) {
      await this.getScopes();
    }
    this.$bus.$emit('spinner-stop');

    await this.getPreferencesAccount();

    this.$bus.$on('closePreferences', (result) => {
      this.getPreferencesAccount();
    });

    this.setPermission(this.permissionList);
  },
  data() {
    return {
      permissionList: new PermissionList(),
      settings: null,
      automaticDays: null,
      // modalPreferencesBase: 'modalPreferencesBase',
      layout: {
        logo: null,
      },
      navigationOptions: [
        { key: 'employee', value: 'Estabelecimento' },
        { key: 'partner', value: 'Parceiro' },
      ],
      showAvatar: false,
      showNavigationSwitcher: false,
      readyToRender: false,
      validSubdomain: null,
      listHiddenButtons: [],
    };
  },
  computed: {
    ...mapGetters({
      token: 'account/token',
      whoAmI: 'account/whoAmI',
    }),
    subdomainValidation() {
      if (this.validSubdomain !== 'gestao') return true;
      return false;
    },
    customersPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'business.customers'
      );
    },
    rolesPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'account.roles'
      );
    },
    usersPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'account.users'
      );
    },
    paymentsPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'payments'
      );
    },
    anticipationPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'payments.anticipation'
      );
    },
    creditPurchasePermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'payments.credits'
      );
    },
    invoicePermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'payments.invoice'
      );
    },
    paymentAccountDashboardPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'paymentAccounts.balance'
      );
    },
    dirfPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'payments.reports.taxSummary'
      );
    },
    paymentAccountTransferManagementPermissionIndex() {
      return this.permissionList.permissions.findIndex(
        (x) => x.name === 'paymentAccounts.settings'
      );
    },
    userName() {
      if (!this.whoAmI) return '';
      return this.whoAmI.user.fullName;
    },
    email() {
      if (!this.whoAmI) return '';
      return this.whoAmI.user.email;
    },
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    isAccountPartner() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByPartner;
    },
    roleName() {
      if (!this.whoAmI) return '';
      if (!this.whoAmI.user.role) return '';
      return this.whoAmI.user.role.name;
    },
    trialTime() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.freeTrial;
    },
    documentSent() {
      if (!this.whoAmI) return '';
      return this.whoAmI.documentSent;
    },
    approved() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.approved;
    },
    isAccountActive() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.active;
    },
    isOriginPartner() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.isOriginPartner;
    },
    roleId() {
      if (!this.whoAmI) return '';
      if (!this.whoAmI.user.role) return '';
      return this.whoAmI.user.role.id;
    },
    customersPermission() {
      if (
        this.permissionList.permissions[this.customersPermissionIndex].grant ===
          'edit' ||
        this.permissionList.permissions[this.customersPermissionIndex].grant ===
          'readonly' ||
        (this.isAccountHolder && !this.isAccountPartner)
      ) {
        return true;
      } else {
        return false;
      }
    },
    customersHolderPermission() {
      if (
        this.permissionList.permissions[this.customersPermissionIndex].grant ===
          'edit' ||
        this.permissionList.permissions[this.customersPermissionIndex].grant ===
          'readonly' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    accountExpired() {
      if (this.approved) {
        return true;
      } else if (!this.approved && this.trialTime) {
        return true;
      } else {
        return false;
      }
    },
    salesPermission() {
      if (
        this.permissionList.permissions[this.paymentsPermissionIndex].grant ===
          'create' ||
        (this.isAccountHolder && this.isAccountActive && !this.isAccountPartner)
      ) {
        return true;
      } else {
        return false;
      }
    },
    accessControlPermission() {
      if (
        this.permissionList.permissions[this.rolesPermissionIndex].grant ===
          'edit' ||
        this.permissionList.permissions[this.rolesPermissionIndex].grant ===
          'readonly' ||
        this.permissionList.permissions[this.usersPermissionIndex].grant ===
          'edit' ||
        this.permissionList.permissions[this.usersPermissionIndex].grant ===
          'readonly' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    rolesPermission() {
      if (
        this.permissionList.permissions[this.rolesPermissionIndex].grant ===
          'edit' ||
        this.permissionList.permissions[this.rolesPermissionIndex].grant ===
          'readonly' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    usersPermission() {
      if (
        this.permissionList.permissions[this.usersPermissionIndex].grant ===
          'edit' ||
        this.permissionList.permissions[this.usersPermissionIndex].grant ===
          'readonly' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    financialPermission() {
      if (
        this.permissionList.permissions[this.paymentsPermissionIndex].grant ===
          'create' ||
        this.permissionList.permissions[this.paymentsPermissionIndex].grant ===
          'readonly' ||
        this.permissionList.permissions[this.anticipationPermissionIndex]
          .grant === 'create' ||
        this.permissionList.permissions[this.anticipationPermissionIndex]
          .grant === 'readonly' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    paymentsPermission() {
      if (
        this.permissionList.permissions[this.paymentsPermissionIndex].grant ===
          'create' ||
        this.permissionList.permissions[this.paymentsPermissionIndex].grant ===
          'edit' ||
        this.permissionList.permissions[this.paymentsPermissionIndex].grant ===
          'readonly' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    anticipationPermission() {
      if (
        this.permissionList.permissions[this.anticipationPermissionIndex]
          .grant === 'create' ||
        this.permissionList.permissions[this.anticipationPermissionIndex]
          .grant === 'edit' ||
        this.permissionList.permissions[this.anticipationPermissionIndex]
          .grant === 'readonly' ||
        (this.isAccountHolder &&
          (!this.isOriginPartner ||
            (this.isAccountHolder && !this.isAccountPartner)))
      ) {
        return true;
      } else {
        return false;
      }
    },
    invoicePermission() {
      if (
        this.permissionList.permissions[this.invoicePermissionIndex].grant ===
          'create' ||
        this.permissionList.permissions[this.invoicePermissionIndex].grant ===
          'readonly' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    servicesPermission() {
      if (
        this.permissionList.permissions[this.invoicePermissionIndex].grant ===
          'create' ||
        this.permissionList.permissions[this.invoicePermissionIndex].grant ===
          'readonly' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    dashboardPermission() {
      if (
        this.permissionList.permissions[
          this.paymentAccountDashboardPermissionIndex
        ].grant === 'readonly' ||
        this.permissionList.permissions[
          this.paymentAccountDashboardPermissionIndex
        ].grant === 'create' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    upcomingReleasesPermission() {
      if (
        this.permissionList.permissions[this.paymentsPermissionIndex].grant ===
          'readonly' ||
        this.permissionList.permissions[this.paymentsPermissionIndex].grant ===
          'create' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    dirfPermission() {
      if (
        (this.permissionList.permissions[this.dirfPermissionIndex] !==
          undefined &&
          this.permissionList.permissions[this.dirfPermissionIndex].grant ===
            'readonly') ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    paymentAccountTransferManagementPermission() {
      if (
        this.permissionList.permissions[
          this.paymentAccountTransferManagementPermissionIndex
        ].grant === 'readonly' ||
        this.permissionList.permissions[
          this.paymentAccountTransferManagementPermissionIndex
        ].grant === 'edit' ||
        this.isAccountHolder
      ) {
        return true;
      } else {
        return false;
      }
    },
    getSettingsFormatting() {
      let daysFormatting = '';
      if (this.settings) {
        if (this.settings.isMonday) daysFormatting = 'Seg, ';
        if (this.settings.isTuesday) daysFormatting += 'Ter, ';
        if (this.settings.isWednesday) daysFormatting += 'Qua, ';
        if (this.settings.isThursday) daysFormatting += 'Qui, ';
        if (this.settings.isFriday) daysFormatting += 'Sex, ';

        if (!daysFormatting) {
          daysFormatting = 'Desligado';
        } else {
          const last = daysFormatting.lastIndexOf(',');
          daysFormatting = daysFormatting.substring(last, '');
        }
      }

      return daysFormatting;
    },
    isNaturalPerson() {
      return this.whoAmI.holder.company == null;
    },
  },
  methods: {
    ...mapActions({
      askWhoAmI: 'account/askWhoAmI',
      setPermission: 'account/setPermission',
      setNavigation: 'layout/setNavigation',
    }),
    notHideTheButton(buttonName) {
      let ret = true;
      if (this.listHiddenButtons == undefined) return ret;
      this.listHiddenButtons.forEach((bt) => {
        if (buttonName === bt.featureName) ret = false;
      });
      return ret;
    },
    hide() {
      /* eslint-disable no-undef */
      $('.app-sidebar').removeClass('show');
    },
    show() {
      /* eslint-disable no-undef */
      $('.app-sidebar').addClass('show');
    },
    async getScopes() {
      this.permissionList.fromScopes(this.whoAmI.user.role.scopes);
    },
    payWithCard() {
      if (this.approved) {
        paggcerto.lightbox({
          environment: process.env.VUE_APP_LIGHTBOX,
          token: this.token,
          payment: {
            amount: null,
            replicateAmount: false,
            bankSlipEnabled: false,
            creditEnabled: true,
            debitEnabled: true,
            payers: [],
          },
          abort: function () {},
          success: function (paymentResult) {},
          fail: function (paymentResult) {},
        });
      } else {
        let msg =
          'A transação com boleto e cartão só estarão disponíveis após a aprovação do cadastro.';
        swal({
          title: '<strong>Ops!</strong>',
          type: 'warning',
          html: `${msg}`,
          confirmButtonText: 'Sair',
        });
      }
    },
    payWithBankSlip() {
      if (this.approved) {
        this.$router.push({ name: 'bankslip' });
      } else {
        let msg =
          'A transação com boleto e cartão só estarão disponíveis após a aprovação do cadastro.';
        swal({
          title: '<strong>Ops!</strong>',
          type: 'warning',
          html: `${msg}`,
          confirmButtonText: 'Sair',
        });
      }
    },
    async removePaymentsHistoric() {
      localStorage.removeItem('historicFilters');
      localStorage.removeItem('totalizers');
    },
    async getPreferencesAccount() {
      this.$bus.$emit('spinner-run');
      const api = new PaymentAccountsApi();
      this.settings = await api.getSettings();
      this.$bus.$emit('spinner-stop');
    },
    // openModal() {
    //   /* eslint-disable no-undef */
    //   $('#modalPreferencesBase').modal('show');
    // },
    changeNavigation(navigation) {
      this.setNavigation(navigation.key);

      window.location = '/';
    },
    getNavigation() {
      return window.localStorage.getItem('navigation');
    },
    isNavigation(navigationName) {
      return window.localStorage.getItem('navigation') === navigationName;
    },
  },
  watch: {
    $route(to, from) {
      if (
        this.$route.name !== 'payment-details' &&
        this.$route.name !== 'sales-history'
      ) {
        this.removePaymentsHistoric();
      }

      this.hide();
    },
  },
};
</script>
<style>
.cursor-pointer {
  cursor: pointer;
}
.white-label__logo {
  max-width: 60%;
  margin: 30px 0;
}
</style>
