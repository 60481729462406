<template>
  <div class="selector">
    <label class="selector__label" @click="handleLabel">{{
      getCurrentOption
    }}</label>
    <div class="selector__options" v-if="isExpanded">
      <ul>
        <li
          v-for="(option, i) in options"
          :key="option.key"
          @click="() => handleOption(i)"
        >
          {{ option.value }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Selector',
  data() {
    return {
      index: 0,
      options: [],
      isExpanded: false,
    };
  },
  beforeMount() {
    this.index = this.defaultIndex || 0;
    this.options = this.defaultOptions;
  },
  computed: {
    getCurrentOption() {
      return this.options[this.index].value;
    },
  },
  methods: {
    handleLabel() {
      this.isExpanded = !this.isExpanded;
    },
    handleOption(selectedIndex) {
      this.index = parseInt(selectedIndex);
      this.isExpanded = false;

      if (typeof this.callback === 'function')
        this.callback(this.options[this.index]);
    },
  },
  props: {
    defaultIndex: {
      type: Number,
      default: 0,
    },
    defaultOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    callback: {
      type: Function,
      default: () => 1,
    },
  },
};
</script>
<style scoped>
.selector {
  display: table;
  font-size: 16px;
}
.selector__label {
  margin: auto;
  cursor: pointer;
  text-align: center;
  color: #ff7606;
}
.selector__label::after {
  content: '\F105';
  font-family: 'FontAwesome';
  float: right;
  margin-top: -4px;
  margin-left: 10px;
  font-size: 20px;
  transform: rotate(90deg);
  cursor: pointer;
}
.selector__options {
  position: absolute;
  background: #ffffff;
  padding: 10px;
  width: 90%;
  margin-left: -30%;
  text-align: center;
  cursor: pointer;
}
.selector__options li {
  padding: 5px 0;
}
.selector__options li:hover {
  color: #ff7606;
}
</style>
