<template>
  <img :src="src" v-if="src" />
  <div class="avatar" v-else>
    <h3>{{ formatedName }}</h3>
  </div>
</template>
<script>
export default {
  name: 'Avatar',
  computed: {
    formatedName() {
      let initials = this.name.match(/\b\w/g) || [];
      return (initials = (
        (initials.shift() || '') + (initials.pop() || '')
      ).toUpperCase());
    },
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
img,
div {
  display: block;
  width: 90px;
  height: 90px;
  border-radius: 100px;
  margin: 0 auto 30px;
  background: #ff7606;
  font-size: 3rem;
  text-align: center;
}
h3 {
  display: inline-block;
  color: #ffffff;
  font-size: 28px;
  margin-top: 30px;
  font-weight: bold;
}
</style>
