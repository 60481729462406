import axios from 'axios';

export default class WarningsApi {
  async getHolderMessages() {
    const messages = await axios.get(
      `${process.env.VUE_APP_WARNINGS_API_URL}/holder/message`
    );
    return messages.data;
  }

  async readMessages(payload) {
    const result = await axios.post(`${process.env.VUE_APP_WARNINGS_API_URL}/holder/recipient-message`, payload);
    return result;
  }
}
