<template>
  <div class="app">
    <app-alert />
    <app-spinner />
    <app-notification />
    <div class="app-body">
      <app-sidebar />
      <div class="app-content">
        <app-breadcrumb />
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import AppAlert from '@/components/theme/Alert';
import AppSidebar from '@/components/theme/Sidebar';
import AppSpinner from '@/components/theme/Spinner';
import AppBreadcrumb from '@/components/theme/Breadcrumb';
import AppNotification from '@/components/theme/Notification';

export default {
  name: 'AppLayout',
  components: {
    AppSidebar,
    AppSpinner,
    AppAlert,
    AppBreadcrumb,
    AppNotification,
  },
};
</script>
