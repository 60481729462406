<template>
  <div class="slide-right drawer">
    <div class="notification-drawer">
      <div class="drawer-header">
        <span class="h3 my-auto ml-5 font-weight-bold"> Notificações </span>
        <div class="btn-notification-close">
          <i class="icon-close"></i>
        </div>
      </div>
      <div class="notifications">
        <div class="notification-section" v-if="today.length > 0">
          <div class="text-muted font-weight-bold pl-5 pt-4">Hoje</div>
          <ul class="notification-list">
            <li
              :class="{
                'notification notification-new': !message.recipientMessage,
                notification: message.recipientMessage,
              }"
              v-for="message of today"
              :key="message.IdMessage"
            >
              <small class="text-muted">{{
                message.createdAt | moment("HH:mm:ss")
              }}</small
              ><br />
              <span class="notification-title">{{ message.title }}</span>
              <p>
                {{ message.description }}
              </p>
            </li>
          </ul>
        </div>
        <div class="notification-section" v-if="yesterday.length > 0">
          <div class="text-muted font-weight-bold pl-5 pt-4">Ontem</div>
          <ul class="notification-list">
            <li
              class="notification"
              v-for="message of yesterday"
              :key="message.IdMessage"
            >
              <small class="text-muted">{{
                message.createdAt | moment("HH:mm:ss")
              }}</small
              ><br />
              <span class="notification-title">{{ message.title }}</span>
              <p>
                {{ message.description }}
              </p>
            </li>
          </ul>
        </div>
        <div class="notification-section" v-if="last.length > 0">
          <div class="text-muted font-weight-bold pl-5 pt-4">Anteriores</div>
          <ul class="notification-list">
            <li
              class="notification"
              v-for="message of last"
              :key="message.IdMessage"
            >
              <small class="text-muted">{{
                message.createdAt | moment("DD/MM/YYYY - HH:mm:ss")
              }}</small
              ><br />
              <span class="notification-title">{{ message.title }}</span>
              <p>
                {{ message.description }}
              </p>
            </li>
          </ul>
        </div>
        <div class="notification-section d-flex h-100" v-if="emptyBoard">
          <div class="m-auto w-75 text-center">
            <span class="display-1 text-muted d-block"
              ><i class="icon-bell"></i
            ></span>
            <h3 class="mb-2">Sem notificações por enquanto</h3>
            <p>
              Em breve aparecerão aqui mensagens sobre seus repasses, alertas
              sobre sua conta e novidades que estamos preparando para você.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-bg-lighter"></div>
  </div>
</template>
<script>
/* eslint-disable no-undef */
import WarningsApi from '@/services/WarningsApi';
import { mapGetters } from 'vuex';
const moment = require('moment');
export default {
  name: 'AppNotification',
  data() {
    return {
      board: null,
      today: [],
      yesterday: [],
      last: [],
      emptyBoard: true,
    };
  },
  async mounted() {
    if (this.whoAmI && this.whoAmI.accessGranted) {
      await this.mountBoard();
    }
    $(async() => {
      $('.modal-bg-lighter').on('click', async() => this.hide());
      $('.btn-notification-close').on('click', async() => this.hide());
      $('.btn-notification-open').on('click', async() => this.show());
    });
  },
  methods: {
    async hide() {
      $('.drawer').removeClass('show');
      this.$bus.$emit('refresh-warnings');
      await this.readWarnings();
    },
    async show() {
      $('.drawer').addClass('show');
      await this.mountBoard();
    },
    async mountBoard() {
      let warningApi = new WarningsApi();
      this.board = await warningApi.getHolderMessages();
      this.emptyBoard = this.board.count === 0;

      this.today = [];
      this.yesterday = [];
      this.last = [];

      const today = moment().endOf('day').format('YYYY-MM-DD');
      const yesterday = moment()
        .add(-1, 'day')
        .endOf('day')
        .format('YYYY-MM-DD');

      if (!this.emptyBoard) {
        this.today = this.board.messages.filter((v) => {
          var date = moment(v.createdAt).format('YYYY-MM-DD');
          if (date === today) return v;
        });

        this.yesterday = this.board.messages.filter((v) => {
          var date = moment(v.createdAt).format('YYYY-MM-DD');
          if (date === yesterday) return v;
        });

        this.last = this.board.messages.filter((v) => {
          var date = moment(v.createdAt).format('YYYY-MM-DD');
          if (date !== today && date !== yesterday) return v;
        });
      }
    },

    async readWarnings() {
      let idsMessages = this.board.messages
        .map((item) => {
          if (!item.recipientMessage) {
            return item.idMessage;
          }
        })
        .filter((v) => {
          if (v !== undefined) return v;
        });

      if (idsMessages.length > 0) {
        var payload = { messages: idsMessages };
        let warningApi = new WarningsApi();
        await warningApi.readMessages(payload);
        this.$bus.$emit('refresh-warnings');
      }
    },
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
  },
};
</script>
