<template>
  <div>
    <div
      class="trial-warning text-center pb-2"
      v-if="trialTime && !approved && whoAmI !== '' && isAccountActive"
    >
      <h5 class="font-weight-bold pt-4 mb-1" v-if="!documentSent">
        Você tem
        {{
          trialTimeLeft === 1
            ? 'até amanhã'
            : trialTimeLeft === 0
            ? 'até hoje'
            : `${trialTimeLeft} dias`
        }}
        para experimentar o sistema.
      </h5>
      <router-link
        :to="{ name: 'activation-start' }"
        class="small-paragraph p-0 breadcrumb-trial-link"
        v-if="!documentSent"
        >Para continuar o cadastro, confirme algumas informações.</router-link
      >
      <h5 class="font-weight-bold pt-4 mb-1" v-if="documentSent">
        Recebemos seus documentos e agora seu cadastro está em análise.
      </h5>
      <p class="small-paragraph p-0 breadcrumb-trial-text" v-if="documentSent">
        Enquanto isso, experimente as funcionalidades do NetBanking.
      </p>
    </div>
    <div
      class="trial-warning text-center pb-2"
      v-if="!trialTime && !approved && whoAmI !== ''"
    >
      <h5 class="font-weight-bold pt-4 mb-1">
        Seu período de experimentação do sistema expirou.
      </h5>
      <router-link
        :to="{ name: 'activation-start' }"
        class="small-paragraph p-0 breadcrumb-trial-link"
        v-if="!documentSent"
        >Para continuar o cadastro, confirme algumas informações.</router-link
      >
      <p class="small-paragraph p-0 breadcrumb-trial-text" v-if="documentSent">
        Aguarde a análise de sua conta para poder utilizar a versão completa.
      </p>
    </div>
    <div
      class="trial-warning text-center pb-2"
      v-if="!isAccountActive && whoAmI !== ''"
    >
      <h5 class="font-weight-bold pt-4 mb-1">
        Essa conta foi encerrada e funciona apenas no modo consulta.
      </h5>
      <p class="small-paragraph p-0 breadcrumb-trial-text">
        Se quiser reativar, entre em contato conosco.
      </p>
    </div>
    <nav class="breadcrumb d-print-none" aria-label="breadcrumb">
      <button type="button" class="btn btn-sidebar btn-sidebar-open">
        <i class="icon-menu"></i>
      </button>
      <ol>
        <li class="breadcrumb-item" v-if="group !== ''">{{ group }}</li>
        <li
          class="breadcrumb-item"
          :class="{
            active:
              dynacrumbContent[dynacrumbContent.length - 1].path ===
              dynacrumb.path,
          }"
          v-for="dynacrumb in dynacrumbContent"
          :key="dynacrumb.path"
        >
          <router-link :to="dynacrumb.path">{{
            dynacrumb.meta.breadcrumb
          }}</router-link>
        </li>
      </ol>
      <div class="btn-group notification">
        <WhiteLabel>
          <div
            class="btn btn-notification-open"
            v-if="!(!trialTime && !approved && whoAmI !== '')"
          >
            <i class="icon-bell"></i>
            <span class="badge badge-danger badge-pill" v-if="count > 0">{{
              count
            }}</span>
          </div>
        </WhiteLabel>
        <WhiteLabel>
          <div class="dropdown">
            <div
              class="btn"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="icon-settings"></i>
              <div class="dropdown-menu">
                <div v-if="!this.isNavigation('partner')">
                  <router-link
                    v-if="
                      isAccountHolder ||
                      (this.whoAmI != null &&
                        this.whoAmI.user.role.id == 'vL' &&
                        !this.whoAmI.accessedByHolder)
                    "
                    class="dropdown-item info font-weight-light m-0"
                    :to="{ name: 'presets' }"
                  >
                    <i class="icon-eye mr-3 align-self-center"></i>Visualizar
                    Conta
                  </router-link>
                </div>
                <div v-if="showCustomize">
                  <router-link
                    v-if="
                      isAccountHolder ||
                      (this.whoAmI != null &&
                        this.whoAmI.user.role.id == 'vL' &&
                        !this.whoAmI.accessedByHolder)
                    "
                    class="dropdown-item info font-weight-light m-0"
                    :to="{ name: 'customize' }"
                  >
                    <i class="icon customize-icon mr-3 align-self-center"></i
                    >Customização
                  </router-link>
                </div>
                <div v-if="this.isNavigation('partner')">
                  <router-link
                    v-if="
                      (isAccountHolder && (isTrialTime || approved)) ||
                      (this.whoAmI != null &&
                        this.whoAmI.user.role.id == 'vL' &&
                        !this.whoAmI.accessedByHolder)
                    "
                    class="dropdown-item info font-weight-light m-0"
                    :to="{ name: 'settings' }"
                  >
                    <i class="icon-settings mr-3 align-self-center"></i>
                    Configurações
                  </router-link>
                </div>
                <a
                  v-if="!this.isOriginPartner && this.showCustomize"
                  class="dropdown-item info font-weight-light m-0"
                  @click="changeNavigation"
                >
                  <i class="icon-people mr-3 align-self-center"></i>
                  Acessar como
                  {{
                    this.isNavigation('partner')
                      ? 'Estabelecimento'
                      : 'Parceiro'
                  }}
                </a>
                <div v-if="!this.isNavigation('partner')">
                  <router-link
                    v-if="
                      (isAccountHolder && (isTrialTime || approved)) ||
                      (this.whoAmI != null &&
                        this.whoAmI.user.role.id == 'vL' &&
                        !this.whoAmI.accessedByHolder)
                    "
                    class="dropdown-item info font-weight-light m-0"
                    :to="{ name: 'fee-list' }"
                  >
                    <i class="icon-calculator mr-3 align-self-center"></i>
                    Minhas Taxas
                  </router-link>
                </div>
                <router-link
                  class="dropdown-item info font-weight-light m-0"
                  :to="{ name: 'change-password' }"
                >
                  <i class="icon-lock mr-3 align-self-center"></i> Alterar Senha
                </router-link>
                <a
                  href="#"
                  class="dropdown-item info font-weight-light m-0"
                  @click.prevent="logout"
                >
                  <i class="icon-logout mr-3 align-self-center"></i>Sair
                </a>
              </div>
            </div>
          </div>
        </WhiteLabel>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WarningsApi from '@/services/WarningsApi';
import WhiteLabel from '@/components/shared/WhiteLabel';
import swal from 'sweetalert2';

export default {
  name: 'AppBreadcrumb',
  components: {
    WhiteLabel,
  },
  async beforeMount() {
    const subdomainRegex = /(?:http[s]*\:\/\/)*(.*?)\.(?=[^\/]*\..{2,5})/i;
    const subdomain = window.location.origin.match(subdomainRegex);

    if (
      !subdomain ||
      subdomain[1] === 'gestao' ||
      !this.whoAmI.accessedByPartner
    ) {
      this.showCustomize = false;
    }
  },
  async mounted() {
    this.insertPage();
    if (this.whoAmI && this.whoAmI.accessGranted) {
      await this.countWarnings();
      this.$bus.$on('refresh-warnings', async () => {
        await this.countWarnings();
      });
    }
  },
  data() {
    return {
      dynacrumbContent: [],
      group: '',
      groupId: 0,
      count: 0,
      showCustomize: true,
    };
  },
  watch: {
    $route(to, from) {
      let index;
      let found = false;
      for (var i = 0; i < this.dynacrumbContent.length; i++) {
        if (this.dynacrumbContent[i].path === this.$route.path) {
          found = true;
          index = i;
          break;
        }
      }
      if (this.groupId === this.$route.meta.groupId) {
        if (found) {
          this.dynacrumbContent.splice(
            index,
            this.dynacrumbContent.length - index
          );
        }
        this.resetDynaCrumb();
        this.insertPage();
      } else {
        if (this.groupId === 8) {
          this.resetPaymentHistoryFilters();
        }
        this.resetDynaCrumb();
        this.insertPage();
      }
    },
  },
  methods: {
    ...mapActions({
      keepCardBrand: 'paymentFilters/keepCardBrand',
      keepSearchType: 'paymentFilters/keepSearchType',
      keepPayerTaxDocument: 'paymentFilters/keepPayerTaxDocument',
      keepPayerNameIndex: 'paymentFilters/keepPayerNameIndex',
      keepPayerName: 'paymentFilters/keepPayerName',
      keepBankslipIdIndex: 'paymentFilters/keepBankslipIdIndex',
      keepBankslipId: 'paymentFilters/keepBankslipId',
      keepNsuIndex: 'paymentFilters/keepNsuIndex',
      keepNsu: 'paymentFilters/keepNsu',
      keepCardFinal: 'paymentFilters/keepCardFinal',
      keepNoteIndex: 'paymentFilters/keepNoteIndex',
      keepNote: 'paymentFilters/keepNote',
      exit: 'account/logout',
      setNavigation: 'layout/setNavigation',
    }),
    insertPage() {
      this.dynacrumbContent.push(this.$route);
      this.group = this.$route.meta.group;
      this.groupId = this.$route.meta.groupId;
    },
    resetDynaCrumb() {
      this.dynacrumbContent = [];
      this.group = '';
      this.groupId = 0;
    },
    logout() {
      this.$alert.question(
        'Sair do sistema',
        'Deseja realmente sair do sistema?'
      );
      this.$alert.onConfirm = async () => {
        this.exit();
        this.$router.push({ path: '/acessar' });
      };
    },
    resetPaymentHistoryFilters() {
      this.keepCardBrand('');
      this.keepSearchType('payerTaxDocument');
      this.keepPayerTaxDocument('');
      this.keepPayerNameIndex(0);
      this.keepPayerName('');
      this.keepBankslipIdIndex(0);
      this.keepBankslipId('');
      this.keepNsuIndex(0);
      this.keepNsu('');
      this.keepCardFinal('');
      this.keepNoteIndex(0);
      this.keepNote('');
    },
    async countWarnings() {
      var warningApi = new WarningsApi();
      var result = await warningApi.getHolderMessages();
      if (result.count > 0) {
        this.count = 0;
        result.messages.forEach((element) => {
          if (!element.recipientMessage) this.count++;
        });
      } else {
        this.count = 0;
      }
    },
    changeNavigation() {
      swal({
        title: 'Acessar como',
        text: `Você deseja alterar seu acesso para o modo ${
          this.isNavigation('partner') ? 'Estabelecimento' : 'Parceiro'
        }?`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const navigationToChange = this.isNavigation('holder')
            ? 'partner'
            : 'holder';

          this.setNavigation(navigationToChange);
          window.location = '/';
        }
      });
    },
    isNavigation(navigation) {
      return window.localStorage.getItem('navigation') === navigation;
    },
  },
  computed: {
    ...mapGetters({
      whoAmI: 'account/whoAmI',
    }),
    isAccountHolder() {
      if (!this.whoAmI) return '';
      return this.whoAmI.accessedByHolder;
    },
    isAccountActive() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.active;
    },
    isOriginPartner() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.isOriginPartner;
    },
    trialTime() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.freeTrial;
    },
    isTrialTime() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.freeTrial > 0;
    },
    documentSent() {
      if (!this.whoAmI) return '';
      return this.whoAmI.documentSent;
    },
    approved() {
      if (!this.whoAmI) return '';
      return this.whoAmI.account.approved;
    },
    trialTimeLeft() {
      if (!this.whoAmI) return '';
      let todaysDate = new Date();
      let createdDate = new Date(this.whoAmI.holder.createdAt);
      return 7 - Math.round((todaysDate - createdDate) / (1000 * 60 * 60 * 24));
    },
    accountCreatedToday() {
      if (!this.whoAmI) return '';
      let todaysDate = new Date();
      let createdDate = new Date(this.whoAmI.holder.createdAt);
      return (
        createdDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)
      );
    },
    hasCompany() {
      if (!this.whoAmI) return '';
      if (!this.whoAmI.holder.company) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>
